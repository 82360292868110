import React from "react";
import styled from 'styled-components';

import { queries } from "../breakpoints";
import Layout from "../components/global/Layout";
import GeneralHeader from "../components/global/GeneralHeader";
import QuoteSection from "../components/sections/QuoteSection";
import CtaSection from "../components/sections/CtaSection";
import ValuePropsSection from "../components/sections/ValuePropsSection";
import HomepageTabbedCardsSection from '../components/page-specific/Homepage/HomepageTabbedCardsSection';

const AboutGrubhubCorporate = ({ location }) => {

  const aboutTabs = [
    {
      heading: "Ways to order",
      cards:[
        { 
          title: "Individual Meals",
          description: "Whether your employees are working remotely or in-office, they can use their meal benefits to get delicious food delivered to wherever they sit, with budgets and rules to control spend.",
          imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797393/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-IndividualMeals.jpg",
        },
        { 
          title: "Group Ordering",
          description: "With more group ordering options on Grubhub Corporate than any other platform, you can let your team order what they want or add food to a shared cart. Feed any work occasion while saving on fees and time!",
          imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797393/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-GroupOrdering.jpg",
        },
        { 
          title: "Catering",
          description: "Feed your next team meeting, corporate event, or business lunch with catering delivered as platters or individually boxed meals. Order ahead or on demand for same-day catering!",
          imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1652231061/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Homepage/EXPORTS-CARD-Catering.jpg",
        }
      ]
    },
    {
      heading: "Ordering occasions",
      cards:[
        { 
          title: "Hybrid Teams",
          description: "Keep your hungry team happy – whether they’re in-office, at home or a mixture of both –  with the most flexible ordering options to feed your hybrid team.",
          imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1661441639/Grubhub%20Corporate%20Website/Hybrid%20Teams%20Page/HybridTeams.jpg",
        },
        { 
          title: "Digital Events",
          description: "Make your virtual client meetings, streamed webinars, and digital lunch and learns more engaging and delicious with food delivery!",
          imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797392/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-DigitalEvents.jpg",
        },
        { 
          title: "Remote Employees",
          description: "Support your remote employees with customizable, world-class meal perks from Grubhub Corporate. Deliver food and happiness to your team!",
          imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797391/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-RemoteEmployees.jpg",
        },
        { 
          title: "Corporate Perks",
          description: "Discover how Grubhub Corporate can fit into your employee benefits package.",
          imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797392/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-CorporatePerks.jpg",
        }
      ]
    }
  ]

  return (
    <Layout 
      location={location} 
      title={"About Grubhub Corporate | Grubhub"}
      description={"Keep your team well-fed and happy Let’s build your meal plan <!– Submit By clicking “Submit” or by using this site, you agree to Grubhub’s terms of use and privacy policy. –> Tasty benefits everyone will enjoy Grubhub Corporate is a simple, customizable and cost-effective way to feed your employees, no matter where they’re […]"}
      noindex={true}
      nofollow={true}
      utag_data={{
        pageGroup: "",
        subGroup: "",
        pageName: "",
        brand: "grubhub"
      }}
      >
      <StyledNavbarOnlyLogo>
        <NavbarContainerOnlyLogo>
          <LogoHeader src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1663686777/HERMES/Logos/GH_Corporate_stacked-sRGB-OrangeGray.png" alt="Grubhub/Seamless Corporate Accounts" placeholder="blurred" width={196}/>
        </NavbarContainerOnlyLogo>
      </StyledNavbarOnlyLogo>
      <GeneralHeader 
        headline="Keep your team well-fed and happy"
        preheader=""
        subheadline=""
        textColor="rgb(51, 51, 51)" 
        background="rgb(248, 248, 248)"
        formID={1808}
        formEnhancedConversions={true}
        businessEmailsUsage={true}
        businessEmailUTMLink='https://www.grubhub.com/lets-eat?utm_source=corporate.grubhub.com&utm_medium=content_owned&utm_campaign=corpmarketing_about_ghc_sem'
        backgroundColorForm='#FFF'
        formHeading="Learn more about the industry leader in flexible meal benefits"
        type="half-image"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1654635347/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/June%207%20Handoff/3-Header-Desktop.png"
      />
      <ValuePropsSection
        headline="Tasty benefits everyone will enjoy"
        subheadline="Grubhub Corporate is a simple, customizable and cost-effective way to feed your employees, no matter where they’re working. It’s free to sign up, and you only pay for the food that’s ordered."
        layout="vertical"
        maxWidth={900}
        valueProps={[
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORT-Homepage-ValueProp1.png",
            headline:"Cater any occasion",
            subheadline:"From business events to team breakfast or lunch deliveries, delight employees and clients with easy, fast and reliable catering delivery services."
          },
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORT-Homepage-ValueProp2.png",
            headline:"Let your team order together",
            subheadline:"Grubhub Corporate Accounts has more group ordering options than any other platform. Plus, your group’s orders arrive together, individually packed."
          },
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORT-Homepage-ValueProp3.png",
            headline:"Meal benefits that go beyond business hours",
            subheadline:"With Grubhub+ perks, your employees can enjoy unlimited $0 delivery fees during or after work* and more!"
          },
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORT-Homepage-ValueProp4.png",
            headline:"Support local restaurants",
            subheadline:"Grubhub has the most local picks compared to any other platform. Support businesses near you with every lunch delivery and catering service you order."
          },
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORT-Homepage-ValueProp5.png",
            headline:"Discover tax-exempt ordering options",
            subheadline:"Grubhub is the only platform with built-in tax-exempt ordering. For admins or team members at eligible organizations,** ordering is made easy with automatic tax removal at checkout on all qualifying orders."
          },
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORT-Homepage-ValueProp6.png",
            headline:"Find flexible and cost-effective solutions",
            subheadline:"Save up to 30% on food costs annually by only paying for what’s ordered.*** Guaranteed on-time delivery at the lowest price or we make it right."
          }
        ]}
      />
      <QuoteSection 
        author="Meghan M"
        position="Office Administrator at Compass"
        backgroundColor="#F7F4F9"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1654635344/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/June%207%20Handoff/3_Compass_Logo.png"
        quote="“Using the corporate features of Grubhub has been instrumental in providing a bit of happiness to our employees during these chaotic times. It's amazing what a meal can do for morale, and the team has been grateful for such a sincere gift!”"
      />
      <TabsHeading>
        <h2>The most ways to order</h2>
        <p>Easy ordering. Delicious food. Reliable delivery to wherever your team sits, with dedicated support every step of the way.</p>
      </TabsHeading>
      <HomepageTabbedCardsSection 
        cards={aboutTabs} 
        backgroundColorTabs="rgb(248, 248, 248)"
      />
      <CtaSection 
          headline="Explore the industry’s most flexible meal perks platform"
          to="#general-header"    
          backgroundColor="#FFF"
      />
      <NoticeContainer>
        <NoticeText>*Valid on eligible orders, additional fees may apply.</NoticeText>
        <NoticeText>**Supporting documentation is required.</NoticeText>
        <NoticeText>***Based on an internal survey of Grubhub Corporate clients.</NoticeText>
      </NoticeContainer>
    </Layout>
  )
}

export default AboutGrubhubCorporate

const NoticeContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: rgb(248, 248, 248);
  padding-top: 60px;
  padding-bottom: 40px;
  @media (${queries.max_break_md}){
    margin: 40px 8% 0px 8%;
  }
  @media (${queries.max_break_sm}){
    margin: 40px 8% 0px 8%;
  }
`;

const NoticeText = styled.p`
  text-align: center;
  font-family: "Scandia Regular", sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: var(--color-secondary);
  @media (${queries.max_break_md}){

  }
  @media (${queries.max_break_sm}){

  }
`;

const StyledNavbarOnlyLogo = styled.header`
    -webkit-box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    -moz-box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    padding: 20px 0;
    z-index: 2000;
    position: relative;
`;

const NavbarContainerOnlyLogo = styled.div`
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    width: 100%;
`;

const LogoHeader = styled.img`
    min-width: 200px;
    align-self: center;
`;

const TabsHeading = styled.div`
  h2 {
    font-weight: bold;
    text-align: center;
    padding-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: rgb(248, 248, 248);
    margin-bottom: 0;
  }
  p {
    font-size: 19px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0;
    background-color: rgb(248, 248, 248);
  }
`;